// =====================================================
// CART
// =====================================================


.b-cart-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (min-width: 740px) {
    flex-direction: row;
  }

  h1 {
    color: #fff;
    font-weight: 300;
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}

.b-cart {
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }

  // cart items wrap
  &__items {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-basis: 75%;
    }
  }

  // single item
  &__item {
    // border-bottom: 1px solid $gray-lightest;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    
    @media (min-width: 900px) {
      flex-direction: row;
    }

    &--even {
      background-color: #f6f6f6;
    }

    &-img {
      flex-basis: 12.5%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 1rem;
      
      @media (min-width: 900px) {
        margin: 0;
        padding-right: 8px;
      }
    }

    &-desc {
      flex-basis: 55%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 1.5rem;
      
      @media (min-width: 900px) {
        margin: 0;
        padding-right: 8px;
        padding-left: 8px;
      }

      h2 {
        margin-bottom: 1rem;
      }

      textarea {
        margin-top: 1rem;
        margin-bottom: .5rem;
        width: 100%;
      }
    }

    &-note {
      color: $gray-lighter;
      font-size: 0.875rem;
      margin-top: 1rem;
    }

    &-qty {
      flex-basis: 100px;
      flex-grow: 1;
      flex-shrink: 0;
      
      @media (min-width: 900px) {
        padding-right: 8px;
        padding-left: 8px;
      }

      input {
        margin-bottom: .5rem;
      }

      input[type="number"] {
        max-width: 100px;
      }

      // label,
      // .btn {
      //   font-size: 0.875rem;
      // }

      // .btn { margin-left: 1.4rem; }
    }

    &-price {
      flex-grow: 1;
      font-size: 1.125rem;
      
      @media (min-width: 900px) {
        padding-left: 8px;
        text-align: right;
      }

      small {
        color: $gray-lighter;
        display: block;
        font-size: 14px;
      }
    }
  }

  &__total {
    background-color: $blue-lightest-1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    flex-grow: 1;
    padding: 24px;
    position: relative;
    text-align: center;

    @include breakpoint(medium) {
      & > div {
        position: sticky;
        top: 24px;
      }
    }
    
    @media (min-width: 900px) {
      text-align: right;
    }

    &-items {
      color: $blue-dark;
    }

    &-price {
      color: $blue;
      font-size: 2.5rem;
      font-weight: 600;
    }

    a {
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }
}