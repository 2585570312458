// =====================================================
// HR
// =====================================================



hr {
  background-color: $gray;
  border: 0;
  display: block;
  height: 1px;
}