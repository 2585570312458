/* =========================================================
   VISUALLY HIDDEN
   - Screen-reader only (hide visually)
========================================================= */

// Screen-reader only (hide visually)
.sr-only {
   border: 0;
   clip: rect(0, 0, 0, 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
}
