// =====================================================
// RESET
// A simple reset that sits on top of Normalize.css
// - Box sizing
// - Reset margins & padding
// - Forms & buttons
// - Media elements
// =====================================================



// Box-sizing
// =====================================================
  html {
    box-sizing: border-box;
  }

  // This probably isn't good practice
  // - https://twitter.com/MiriSuzanne/status/1145081498594398209
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }



// Reset margins and paddings on most elements
// =====================================================
  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, 
  // ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin: 0;
    padding: 0;
  }



// Forms & buttons
// =====================================================
  input,
  textarea,
  select,
  button {
    color: inherit; 
    font: inherit; 
    letter-spacing: inherit; 
  }



// Media elements
// =====================================================
  embed,
  iframe,
  img,
  object,
  video {
    display: block;
    max-width: 100%;
  }