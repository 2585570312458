// =====================================================================
// TOOLS
// Site-wide mixins and functions
// 
// Contents
// - Breakpoints mixin
// - Clearfix
// - Hide visually
// - Rem-calc
// - Spacing
// =====================================================================


// Breakpoints mixin
// e.g: @include breakpoint(medium);
// =====================================================
@mixin breakpoint($size) {
  @if $size == medium {
    @media (min-width: $md) { @content; }
  }

  @else if $size == large {
    @media (min-width: $lg) { @content; }
  } 

  @else if $size == xlarge {
    @media (min-width: $xl) { @content; }
  }
}

// Clearfix
// Mixin to drop micro clearfix into a selector. 
// Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
// =====================================================
@mixin clearfix() {
  &:after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}


// Hide visually
// Mixin to quickly apply accessible hiding to elements.
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
// =====================================================
@mixin hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}


// Rem-calc
// =====================================================
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}


// Spacing
// =====================================================
@function space($num) {
  @if map_has_key($spacing, $num) {
    @return map-get($spacing, $num);
  }
}