// =====================================================
// TYPOGRAPHY
// =====================================================

p {
  margin-bottom: 1rem;
}

h1.page-heading {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}