/* =========================================================
	MAIN SASS FILE
	Loosely based on ITCSS methodology
	- Generic: Low-specificity styles meant to be applied all over the DOM
	- Vendor: Styles for third-party stuff
	- Elements: All unclassed HTML tags
	- Blocks: Pieces of UI that can be reused in one or more spots
	- Utilities: High-specificity, very explicit selectors. (Overrides and helper classes.)
========================================================= */
@charset 'utf-8';

/* Generic ==== */
@import 'generic/settings';
@import 'generic/tools';
@import 'generic/reset';
// @import '../../node_modules/normalize-scss/sass/normalize';

/* Vendor ==== */
// Font Awesome v5
// @import "vendor/fontawesome/fontawesome.scss";
// @import "vendor/fontawesome/light.scss";
// @import "vendor/fontawesome/regular.scss";
// @import "vendor/fontawesome/solid.scss";
// @import "vendor/fontawesome/brands.scss";
@import "vendor/jquery-modal";
@import "vendor/parsley";

/* Elements ==== */
@import 'elements/html';
@import 'elements/hr';
@import 'elements/links';
@import 'elements/type';
@import 'elements/images';
// @import 'elements/lists';
// @import 'elements/tables';
// @import 'elements/noscript';

/* Blocks ==== */
@import 'blocks/buttons';
@import 'blocks/forms';
@import 'blocks/errors'; // also holds alerts
@import 'blocks/home-login';
// @import 'blocks/home';
@import 'blocks/layout';
@import 'blocks/nav';
@import 'blocks/pagination';
@import 'blocks/products';
@import 'blocks/cart';
@import 'blocks/order';
@import 'blocks/footers';
@import 'blocks/search';
@import 'blocks/modals';
@import 'blocks/matrix';

/* Utiltities ==== */
// e.g. .hidden, .relative, .one-half, etc.
// Only place where !important is permitted.
// @import 'utilities/clearfix';
// @import 'utilities/hide';
@import 'utilities/visually-hidden';
