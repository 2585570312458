// =====================================================
// IMAGES
// =====================================================


img.border-blue {
  border: 3px solid $blue;
}

.image-block {
  margin-bottom: 1rem;
}