// =====================================================
// FOOTERS
// =====================================================


.b-footer {
  margin: 24px auto 40px;
  max-width: 1000px;
  text-align: center;

  p {
    color: $blue-lightest;
    font-size: 0.875rem;
    margin-bottom: 16px;
    padding: 0 1.5rem;

    @include breakpoint(medium) {
      padding: 0;
    }

    a {
      color: $blue-lighter;
    }
  }
}