// =====================================================
// ERRORS
// =====================================================


.b-error {
  background-color: $error-lightest;
  border: 1px solid $error-dark;
  border-radius: 3px;
  color: $error-dark;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;

  p span { display: none; }
}

.b-warning {
  background-color: $yellow-lightest-1;
  border: 1px solid $yellow;
  border-radius: 3px;
  color: $gray;
  margin: 0 auto;
  max-width: 50%;
  padding: 1rem;
  text-align: center;
}