// =====================================================
// BUTTONS
// =====================================================


.btn,
button {
  background-color: $red;
  border: 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  padding: 8px 24px;
  text-decoration: none;
  transition: .3s;

  &:hover {
    background-color: $red-dark;
    cursor: pointer;
    transition: .3s;
  }

  &--blue {
    background-color: $blue;

    &:hover { background-color: $blue-dark; }
  }

  &--hollow {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;

    &:hover {
      background-color: transparent;
      color: $red-darker;
    }

    &.btn-sm {
      font-size: .75rem;
      padding: 4px 12px;
    }
  }

  &--full {
    width: 100%;
  }
}