// =====================================================
// PRODUCTS
// - Products nav
// - Product rows (for listings)
// - Detail
// =====================================================


// Products nav
// =====================================================
//   .b-products-nav {
//     align-items: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     margin-bottom: 24px;

//     @include breakpoint(medium) {
//       flex-direction: row;
//     }

//     &__links {
//       background-color: $blue-dark;
//       border-radius: 3px;
//       box-shadow: inset 0 0 4px 2px rgba(#000, .3);
//       color: #fff;
//       display: flex;
//       flex-wrap: nowrap;
//       list-style: none;
//       margin-bottom: 16px;
//       max-width: 100%;
//       overflow-x: auto;
//       padding: .5rem;
//       // text-align: center;
//       -webkit-overflow-scrolling: touch;
//       -ms-overflow-style: -ms-autohiding-scrollbar;

//       &::-webkit-scrollbar { display: none; }

//       @include breakpoint(medium) {
//         margin: 0 1rem 0 0;
//       }

//       li {
//         border-radius: 3px;
//         display: inline-block;
//         font-size: 0.875rem; // 14px
//         line-height: 1.5rem;
//         margin-right: 8px;
//         text-transform: uppercase;

//         a {
//           color: #fff;
//           display: block;
//           padding: 4px 12px;
//           text-decoration: none;
//         }

//         &.active {
//           background-color: #fff;
//           font-weight: bold;
//         }

//         &.active a {
//           color: $blue;
//         }
//       }
//     }

//     &__search {
//       input[type="search"] {
//         border-radius: 3px 0 0 3px;
//       }

//       input[type="submit"] {
//         border-radius: 0 3px 3px 0;
//       }
//     }
//   }
.products-nav {
	@include breakpoint(large) {
		display: grid;
		grid-template-columns: 60% auto;
		gap: 1.5rem;
		margin-bottom: 1.5rem;
		align-items: center;
	}

	&__search {
		border-bottom: 1px solid rgba(white, 0.25);
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;

		@include breakpoint(large) {
			border-bottom: 0;
			order: 2;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	&__categories {
		color: white;
		@include breakpoint(large) {
			order: 1;
			position: relative;
			border-right: 1px solid rgba(white, 0.25);
			padding-right: 1.5rem;
		}

		label {
			background-color: $red;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 3px;
			padding-block: 0.5rem;
			padding-inline: 1rem;
			margin-bottom: 1rem;
			@include breakpoint(large) {
				margin-bottom: 0;
			}

			&:hover { cursor: pointer; }

			&::after {
				content: "";
				width: 20px;
				height: 20px;
				background-image: url(/assets/img/icon-caret-down.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80%;
				display: inline-block;
				// transform: rotate(-90deg);
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: none;
		}

		ul li {
			color: inherit;
			border-radius: 3px;
			padding: 0.5rem;

			+ li {
				margin-top: 0.25rem;
			}

			&.active {
				background-color: $red;
			}
		}

		ul li a {
			color: inherit;
			text-decoration: none;
			display: block;
		}

		ul li:hover {
			cursor: pointer;
			background-color: $blue-lighter;
		}

		input#category-selection { display: none; }

		input#category-selection:checked + label {
			&::after {
				transform: rotate(-90deg);
			}
		}

		input#category-selection:checked + label + ul {
			display: block;
			position: absolute;
			background-color: $blue;
			z-index: 1;
			padding: 1rem;
			border: 1px solid $red;
			border-radius: 3px;
			width: calc(100% - 4rem);
			margin-top: calc((1rem - 2px) * -1);
			@include breakpoint(large) {
				margin-top: 2px;
				width: calc(100% - 1.5rem);
			}
		}
	}
}


// Product rows (for listings)
// =====================================================
  .b-product-row {
    border-bottom: 1px solid $blue;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &__img {
      align-items: flex-start;
      flex-basis: 30%;
    }

    &__info {
      flex-basis: 65%;
      flex-grow: 1;
      padding-left: 1.5rem;
    }
  }


// Detail
// =====================================================
  .b-product {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &__img {
      margin-bottom: 1.5rem;

      @include breakpoint(medium) {
        flex-basis: 40%;
        margin-bottom: 0;
      }

      img { margin: 0 auto; }
    }

    &__info {
      @include breakpoint(medium) {
        flex-grow: 1;
        margin-left: 48px;
      }
    }

    &__type {
      margin-top: .5rem;
      margin-bottom: 1rem;
    }

    &__dimensions {
      background: $yellow;
      padding: 1rem;
    }

    &__price {
      font-size: 2rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .b-materials {
    background-color: #fff;
    padding: 8px 32px;

    &__row {
      border-bottom: 1px solid $blue;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 16px;

      &:last-of-type { border: 0; }
    }

    &__durability {
      border-right: 1px solid $gray-lightest;
      padding-right: 24px;
      padding-left: 24px;
      min-width: 25%;
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;

      h4 {
        color: $blue;
        font-size: 1.75rem;
        font-weight: 300;
        line-height: 1.25;
        margin-bottom: 24px;
        text-transform: uppercase;
      }

      p {
        color: $blue;
        font-size: 3rem;
      }
    }

    &__info {
      margin-left: 32px;
      padding-top: 16px;
      padding-bottom: 16px;

      h5 {
        font-size: 1.3125rem; // 21px
        text-transform: uppercase;
      }

      p:first-of-type { margin-bottom: 24px; }
    }
  }
