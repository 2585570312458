// =====================================================
// LINKS
// =====================================================



a {
  color: $blue;
  text-decoration: underline;

  &:hover {
    // color: ;
    text-decoration: none;
  }

  &.inverse { color: #fff; }
}