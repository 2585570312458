// =====================================================================
// SETTINGS
// The global settings file contains project-wide variables;
// things that need to be made available to the entire codebase.
// 
// Contents
// - Fonts
// - Colors
// - Breakpoints
// - Spacing
// =====================================================================


// Fonts
// =====================================================
$roboto: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
$body: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif;


// Colors
// =====================================================
// Brand colors
$blue: #074775;
$blue-light: #095a95;
$blue-lighter: #0c78c5;
$blue-lightest: #73c0f7;
$blue-lightest-1: #d3ebfc;
$blue-dark: #042a45;

$red: #dc4a2a;
$red-light: #e36f56;
$red-lighter: #e88873;
$red-lightest: #efad9f;
$red-lightest-1: #f9dfd9;
$red-dark: #b5391e;
$red-darker: #7b2714;
$red-darkest: #4f190d;

$yellow: #ebab2e;
$yellow-light: #efbe5c;
$yellow-lighter: #f2ca7b;
$yellow-lightest: #f7ddaa;
$yellow-lightest-1: #fbf0d8;

$gray: #333;
$gray-light: #4c4c4c;
$gray-lighter: #6e6e6e;
$gray-lightest: #aaa;
$gray-lightest-1: #eee;
$gray-dark: #222;

// Supporting colors
$error: #ba2525;
$error-light: #d64545;
$error-lighter: #f29b9b;
$error-lightest: #ffeeee;
// $red-lightest-1: ;
$error-dark: #911111;
$error-darker: #610404;
// $red-darkest: ;
// $red-darkest-1: ;


// Breakpoints
// Set the breakpoints as variables
// =====================================================
$md: 640px; // and up
$lg: 1024px; // and up
$xl: 1280px; // and up