// =====================================================
// FORMS
// - Text inputs
// - Selects/dropdowns
// - Forms
// =====================================================


// Text inputs
// =====================================================
input[type="text"],
input[type="password"],
input[type="search"],
input[type="number"] {
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  color: $gray;
  display: block;
  font-size: 1rem;
  width: 100%;
  padding: 8px 16px;

  &:focus { border-color: $blue-light; }
}

textarea {
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  color: $gray;
  display: block;
  font-size: 1rem;
  max-width: 100%;
  padding: 8px 16px;
}

label .info {
  font-size: 0.875rem;
  margin-left: 8px;
}


// Selects/dropdowns
// =====================================================
select {
  background-color: #fff;
  // 2 backgrounds; first for arrow icon, second for gradient
  background-image: url(/assets/img/icon-caret-down.svg), linear-gradient(to bottom, #fff 0%, #eee 100%);
  background-position: right .7em top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: .65em auto, 100%;
  border: 1px solid $gray;
  border-radius: 3px;
  display: block;
  line-height: 1.5;
  margin: 0;
  width: 100%;
  padding: 8px 40px 8px 16px;
  appearance: none;

  // hide arrow icon in IE browsers
  &::-ms-expand { display: none; }

  &:hover { border-color: $gray-light; }
  &:focus { border-color: $blue-light; }
}


// Forms
// =====================================================
.f-login {
  input { width: 100%; }

  input[type="text"],
  input[type="password"] {
    margin-bottom: 1.5rem;
  }
}

.f-search {
  display: flex;
  flex-direction: column;
	gap: 0.25rem;

  @media (min-width: 375px) {
    flex-direction: row;
  }

  @media (max-width: 374px) {
    input { border-radius: 3px !important; }
    input[type="search"] { margin-bottom: 4px; }
  }

  input[type="search"] { border: 0; }
}

.f-add-to-cart {
  margin-top: 1.5rem;

  select {
    margin-bottom: 24px;
  }
}

.f-cart-po {
  margin: 0 auto;
  max-width: 560px;
  padding: 1rem;

  @include breakpoint(medium) {
    padding: 40px;
  }

  fieldset {
    border: 0;
    margin-top: 24px;
    margin-bottom: 32px;

    label {
		display: block;

		+ label {
			margin-top: 1rem;
		}

      &:first-child { padding-left: 0; }
      &:last-child { padding-right: 0; }

      input { width: 100%; }
    }
  }

  &__note {
    color: $blue;
    font-style: italic;
    margin-top: 56px;
    margin-bottom: 24px;
  }
}

.f-order-summary {
  display: flex;
  flex-direction: column;

  .btn-wrap {
    text-align: right;
  }
}
