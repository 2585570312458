// =====================================================
// ORDERS
// =====================================================


.b-order-details {
  display: flex;
  flex-direction: column;
  
  @include breakpoint(medium) {
    flex-direction: row;
    margin-bottom: 3rem;

    div {
      flex-basis: 50%;
    }
  }
  
  div:first-child {
    margin-bottom: 1rem;
  }

  .note {
    background-color: $yellow-lightest;
    padding: 16px;
    @include breakpoint(medium) {
      margin-right: 32px;
    }
  }
}


.b-order-items {
  margin-top: 1rem;
  @include breakpoint(medium) {
    margin-top: 1.5rem;
  }

  &__row {
    align-content: flex-start;
    border-bottom: 1px solid $gray-lightest;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0;
    @include breakpoint(medium) {
      // padding: 1rem;
    }

    &:nth-child(2) {
      border-top: 1px solid $gray-lightest;
      // padding-top: .25rem;
    }

    &-header {
      display: none;
      // border-bottom: 2px solid $gray;
      // border-width: 2px 0 1px 0;
      font-weight: 800;
      // margin-bottom: 1rem;
      // padding: .5rem 1rem;
      // @include breakpoint(medium) {
      //   margin-bottom: .5rem;
      // }

      p {
        font-weight: 800;
      }
    }
  }

  &__item {
    flex-basis: 100%;
    // // flex-grow: 1;
    margin-bottom: .5rem;
    // padding-right: .5rem;

    span {
      color: $gray-lighter;
      display: block;
      font-size: .75rem;
    }
  }

  &__price {
    flex: auto auto 30%;
    // padding: 0 .5rem;
    padding-right: .5rem;
  }

  &__qty {
    flex: auto auto 30%;
    // padding: 0 .5rem;
    // text-align: center;
  }

  &__total {
    flex: 1 30%;
    // flex-grow: 1;
    text-align: right;
  }

  &__footer {
    padding: 1rem 0;

    p {
      font-size: 1.3125rem;
      text-align: right;
    }
  }
}