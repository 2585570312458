// =====================================================
// HOME - LOGIN PAGE
// Blocks only for the login page
// =====================================================


.b-home-wrapper {
  background-color: $blue;
  background-position: center center;
  background-size: cover;
  color: #fff;
  flex-grow: 1;
  height: 100%;
}

.b-branding {
  margin: 0 auto;
  padding: 48px 16px 40px;
  text-align: center;

  img {
    margin: 0 auto;
  }
}

.b-login {
  margin: 0 auto;
  max-width: 280px;
  padding-right: 16px;
  padding-left: 16px;

  label > input {
    display: block;
  }

  .b-btn {
    width: 100%;
  }

  &__utility {
    font-size: 12px;
    margin-top: 24px;
    text-align: center;

    a { color: #fff; }
  }
}