// =====================================================
// HTML
// Simple page-level setup.
// 
// 1. Set the default `font-size` and `line-height` for the entire project.
// 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
//    scrollbars naturally.
// =====================================================



html {
  background: #fff;
  color: $gray;
  font-family: $body;
  font-size: 1rem; // 1. 16px
  line-height: 1.5; // 1.
//   overflow-y: scroll; // 2.
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // &.vh {
  // }
}