/* =========================================================
	LAYOUTS
	- Outer container
	- Main container
	- Sidebar layout
========================================================= */

/* Outer container ==== */
.outer-container {
	background-color: $blue;
	flex-grow: 1;
	padding-top: 1rem;
	@include breakpoint(large) { padding-top: 2rem; }
}

/* Main container ==== */
.container {
	margin-inline: auto;
	max-width: 90%;

	@include breakpoint(large) {
		width: 90%;
		max-width: 1200px;
	}

	&-content {
		background-color: #fff;
		border-radius: 3px;
		margin-bottom: 40px;
		padding: 24px;
	}
}

/* Sidebar layout ==== */
// .container .has-sidebar {
// 	display: grid;
// 	gap: 2rem;

// 	@include breakpoint(large) {
// 		grid-template-columns: auto 20%;
// 	}

// 	> section {
// 		order: 2;
// 		background-color: white;
// 		padding: 1rem;
// 		border-radius: 3px;
// 		@include breakpoint(large) { order: 1; }
// 	}

// 	> div {
// 		order: 1;
// 		@include breakpoint(large) { order: 2; }
// 	}
// }
