// =====================================================
// STYLING FOR MATRIX BLOCKS
// =====================================================


.matrix-base {
  
  .redactor + img {
    margin-top: 4rem;
  }
}