// =====================================================
// MODALS
// =====================================================


// Materials modal
.modal {
  &--materials {

  }

  &__heading {
    color: #fff;
    font-size: 2.5rem; // 40px
    margin-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
  }
}