// =====================================================
// PAGINATION
// =====================================================


.b-pagination {
  text-align: center;

  a {
    vertical-align: text-bottom;
  }

  &__prev,
  &__next {
    background-color: $blue;
    border-radius: 2px;;
    color: #fff;
    margin-left: 16px;
    margin-right: 16px;
    padding: 4px 8px;
	 text-decoration: none;

    &:hover {
      background-color: $red;
    }
  }

  &__page {
    margin: 0 16px;
    text-decoration: none;

    &:hover {
      color: $red;
    }
  }

  &__current {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 16px;
  }
}
