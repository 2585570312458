/* =========================================================
	NAV
========================================================= */
.b-nav {
   display: flex;
   flex-direction: column;
   padding: 1rem;

   @include breakpoint(medium) { align-items: center; }

	@include breakpoint(large) {
		flex-direction: row;
		gap: 4rem;
		justify-content: space-between;
	}

   &__logo {
      margin: 0 auto;

      @include breakpoint(medium) {
         margin: 0;
      }
   }

   &__links {
      list-style: none;
      margin-top: 1rem;
      padding: 0;
		display: grid;
		gap: 0.5rem;

      @include breakpoint(medium) {
			column-gap: 1rem;
			row-gap: 0.5rem;
			grid-template-columns: repeat(3, 1fr);
      }

		@include breakpoint(large) {
			display: flex;
			flex-wrap: wrap;
		}

		@include breakpoint(xlarge) { column-gap: 2.5rem; }

      li {
			line-height: 1.1;
			@include breakpoint(large) { max-width: 120px; }
			@include breakpoint(xlarge) { max-width: 150px; }
      }

		li a {
			display: flex;
			gap: 0.75rem;
			align-items: center;
			text-decoration: none;

			@include breakpoint(large) {
				display: grid;
				gap: 0.5rem;
				justify-content: center;
				text-align: center;
			}

			&:hover { text-decoration: underline; }
		}

      li span {
			font-size: 0.875rem;
			@include breakpoint(xlarge) { font-size: 1rem; }
      }

		li img {
			width: 32px;
			@include breakpoint(large) { margin-inline: auto; }
			@include breakpoint(xlarge) { width: 40px; }
		}
   }
}
