// =====================================================
// SEARCH
// =====================================================


.b-search-results {
  margin-top: .5rem;
}

.b-search-item {
  // border-bottom: 1px solid $gray-lightest;
  padding: .5rem;
}